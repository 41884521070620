<template>
  <div class="leftbox">
    <div class="toptimebox">
      <!-- <p>{{date.year}}年{{date.month}}月{{date.day}}日</p>
      <p>{{nowDate}}</p> -->

      <div id="he-plugin-simple" style="z-index:3"></div>
      <div class="colorbox" :style="colorstyle">
        {{briefNew}}
      </div>
    </div>
    <div class="left-record">
      <TitleBox :titlename="'告警统计'" />

      <div class="tabbox">
        <div class="libox">
          <!-- <p>设备数量</p> -->
          <p>区域</p>
          <p>设备告警次数</p>
          <p>工单告警次数</p>
        </div>
        <ul>

          <li v-for="(item,index) in tableData2" :key="index">
            <p>{{item.areaName}}</p>
            <p>{{item.numberWarnings}}</p>
            <p>{{item.rederNumberWarnings}}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="left-record">
      <TitleBox :titlename="'告警工单'" />
      <div class="tabbox">
        <div class="libox">
          <p>日期</p>
          <p>处理人</p>
          <p>违法类型</p>
          <p>处理结果</p>
        </div>
        <ul>
          <vue-seamless-scroll :data="tableData1" class="seamless-warp"
            :class-option="defaultOption">
            <li v-for="(item,index) in tableData1" :key="index" @click="gotoodr(item)">
              <p>{{item.updataTime}}</p>
              <p>{{item.name}}</p>
              <p>{{item.wfType}}</p>
              <p>{{item.result}}</p>
            </li>

          </vue-seamless-scroll>
        </ul>
      </div>
    </div>
    <div class="left-record">
      <TitleBox :titlename="'告警信息'" />
      <div class="tabbox">
        <div class="libox">
          <p>日期</p>
          <p>告警区域</p>
          <p>告警信息</p>
        </div>
        <ul>
          <vue-seamless-scroll :data="tableData" class="seamless-warp"
            :class-option="defaultOption">

            <li v-for="(item,index) in tableData" :key="index">
              <p>{{item.data}}</p>
              <p>{{item.szName}}</p>
              <p>{{item.wfType}}</p>
            </li>
          </vue-seamless-scroll>
        </ul>

      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import TitleBox from '../title/index.vue'
import vueSeamlessScroll from 'vue-seamless-scroll' // 引用实时预警文本上下滚动组件
import { formatDate } from '../../util'
import { HongHuweather } from './weather'

export default {
  props: ['env', 'radarnum'],
  components: {
    TitleBox,
    vueSeamlessScroll // 实时预警文本上下滚动组件
  },
  data () {
    return {
      flagDb: true,
      tableData: [],
      date: {
        year: '',
        month: '',
        day: '',
        week: ''
      },
      tableData1: [],
      tableData2: [],
      nowDate: '',
      Orderobj: {
        pagSum: 1, // 页码
        pageSize: 10 // 页大小
      },
      // timer: null

      city: '洪湖',
      tmpNew: '',
      briefNew: '暂无预警',
      colorstyle: 'background: transparent',
      paramsTimer: null, // 定时器
      Weathernum: 1, // 定时器循环变量
      Weatherobj: HongHuweather
    }
  },
  computed: {
    // 报警文本滚动
    defaultOption () {
      return {
        step: 1, // 数值越大速度滚动越快
        limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  watch: {
    env: {
      // immediate: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          this.env = newvalue
          // console.log(this.env, 'zizujianop')
        })
      }
    },
    radarnum: {
      deep: true,
      // immediate: true,
      handler (newvalue, lodvalue) {
        // console.log(newvalue, lodvalue)
        this.$nextTick(() => {
          if (newvalue !== 0) {
            // this.opclose(1)
          }
        })
      }
    }
  },
  // mounted () {
  //   // this.$nextTick(function () {
  //   //   setInterval(this.pagetiem(), 1000)
  //   // })
  // },
  methods: {
    pageControlPanl () {
      axios.get(this.$constant.zsqyUrl + '/zsqy/drinfo/findControlPanl',
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          // console.log(res, '0--')
          this.tableData2 = res.data.data
          this.tableData2.forEach(ele => {
            ele.state = false
          })
        })
    },
    gotoodr (item) {
      // const { href } = this.$router.resolve({
      //   path: '/visualization/warningdetails/' + item.warningId + '/1'
      // })
      // window.open(href, '_blank')
    },
    // showimg (item, index) {
    //   this.tableData2[index].state = !this.tableData2[index].state
    //   if (item.state) {
    //     this.env.GetControlsurfaceHeler().Show(item.drId)
    //   } else {
    //     this.env.GetControlsurfaceHeler().Hide(item.drId)
    //   }
    //   this.$forceUpdate() // 强制渲染
    // },
    pagetiem () {
      // 查询日期
      const date = new Date()
      this.date.year = date.getFullYear()
      this.date.month = date.getMonth() + 1
      this.date.day = date.getDate()
      const arr = ['一', '二', '三', '四', '五', '六', '日']
      this.date.week = arr[date.getDay() - 1]
      this.nowDate = formatDate(new Date().getTime())
    },
    setIntervaltime () {
      this.timer = setInterval(() => {
        this.pagetiem()
        // console.log('---')
      }, 1000)
    },
    searchWeather: async function () {
      const key = '10198e0e3b94479cbe68010db4c0e8dd'
      const httpUrl = `https://geoapi.qweather.com/v2/city/lookup?location=${this.city}&key=${key}`
      const res = await fetch(httpUrl)
      const result = await res.json()
      const id = result.location[0].id
      const httpUrl1 = `https://devapi.qweather.com/v7/warning/now?location=${id}&key=${key}`
      const res1 = await fetch(httpUrl1)
      const result1 = await res1.json()
      // console.log(result2, 'result2')
      if (result1.warning && result1.warning.length > 0) {
        const now = result1.warning[0]
        this.tmpNew = now.level
        if (this.tmpNew === '黄色') {
          this.colorstyle = 'background: #f0eb2c'
        } else if (this.tmpNew === '橙色') {
          this.colorstyle = 'background: #d35c43'
        } else if (this.tmpNew === '蓝色') {
          this.colorstyle = 'background: #0e4f9b'
        } else if (this.tmpNew === '红色') {
          this.colorstyle = 'background: #ef1b2d'
        }
        this.briefNew = now.typeName
      }
    },
    Weathertime () {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src =
        'https://widget.qweather.net/simple/static/js/he-simple-common.js?v=2.0'
      document.getElementsByTagName('head')[0].appendChild(script)
      window.WIDGET = {
        CONFIG: HongHuweather
      }
      this.searchWeather()
    },
    Weatherpage () {
      this.Weathertime()
    },
    pageOrder () { // 本地接口
      // this.tableData1 = []
      this.tableData = []
      axios.get(this.$constant.zsqyUrl + '/zsqy/Radarworkorder/findAll',
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          // console.log(res, 'suoyou ')
          const list = []
          const list2 = []
          res.data.data.forEach(ele => {
            if (ele.result) {
              list2.push(ele)
            } else {
              list.push(ele)
            }
          })
          // 重新赋值  解决自动滚动问题
          this.tableData1 = list2
          this.tableData = list

          // this.tableData1.push(obj)
          // this.tableData1 = res.data.data.records
          // console.log(this.tableData1, 'this.tableData1')
        })
      // this.$forceUpdate() // 强制渲染
    }
  },
  created () {
    this.pagetiem()
    this.setIntervaltime()
    this.Weatherpage()
    this.pageOrder()
    this.pageControlPanl()
  },
  mounted () {
  },
  destroyed () {
    clearInterval(this.paramsTimer) // 关闭定时任务
    // console.log('xiaohui ')
  }
}
</script>
<style lang="less" scoped>
.leftbox {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  .echarts {
    width: 100%;
    height: 100%;
  }
  .toptimebox {
    width: 100%;
    height: 4%;
    font-size: 20px;
    font-family: SourceHanSansCN;
    font-weight: bold;
    color: #fff;
    // color: #a5b6be;
    // background: #a5b6be;
    position: relative;
    .colorbox {
      background: transparent;
      width: 100px;
      height: 25px;
      position: absolute;
      top: 10%;
      right: 10%;
      text-align: center;
      border-radius: 15px;
      // line-height: 25px;
      font-size: 14px;
      cursor: pointer;
    }
    /deep/.wv-lt-col-5 {
      display: none;
    }
  }

  .left-record {
    width: 100%;
    height: 28%;
    margin-top: 2%;
    // position: relative;
    ul li {
      margin: 0;
      padding: 0;
    }
    .tabbox {
      width: 100%;
      height: 73%;
      margin-top: 15px;
      .libox {
        width: 98%;
        height: 40px;
        // background: #e6fcff;
        display: flex;
        // justify-content: space-between;
        // text-align: center;
        p {
          width: 33%;
          color: #fff;
          text-align: center;
        }
      }
      div {
        z-index: 2;
      }
      ul {
        width: 100%;
        height: 84%;
        overflow-y: auto;

        li {
          width: 100%;
          height: 40px;
          display: flex;
          // justify-content: space-between;
          // align-items: center;
          line-height: 40px;
          // background: RGBA(56, 74, 97, 0.2);
          p {
            width: 33%;
            color: #fff;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            text-align: center;
            font-size: 10px;
            font-family: SourceHanSansCN;
            font-weight: 300;
          }
        }
        li:nth-child(2n) {
          background: RGBA(52, 75, 87, 0.6);
        }
        li:hover {
          background: rgba(79, 119, 163, 0.4);
        }
      }
      /* 设置滚动条的样式 */
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background: RGBA(56, 74, 97, 0.2);
      }
      /* 滚动条滑块 */
      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: RGBA(56, 74, 97, 0.6);
        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
      }
    }
    .btnbox {
      width: 50%;
      height: 40px;
      margin: 5px 0 0 60%;
    }
  }
  .left-record:nth-child(1) {
    height: 40%;
    p {
      width: 25%;
    }
  }
  .left-record:nth-child(2) {
    p {
      width: 25%;
    }
    height: 20%;
  }
  .left-record:nth-child(3) {
    margin-top: 10%;
    p {
      width: 15%;
    }
    p:nth-child(1) {
      width: 55%;
    }
    height: 35%;
  }
  .left-record:nth-child(4) {
    p {
      width: 33%;
      // text-align: center;
    }
    height: 35%;
  }
}
</style>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
